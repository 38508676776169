// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
// @import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}
/* Modification Phil#3 - 2019-12-19 */

p.infoCoordonnees {
    color: #fff;
    margin: 0;
}
p.infoCoordonnees.infoCourriel {
    margin-bottom: 25px;
}
//input[type="checkbox"] {
//    height: 25px;
//    width: 25px;
//    background-color: #eee;
//    border-radius: 50%;
//    transition: .5s;
//    position: relative;
//    top: 8px;
//    margin-right: 5px;
//    margin-bottom: 5px;
//}
//.content-radios-btns {
//    display: block;
//    position: relative;
//    margin-bottom: 20px;
//    cursor: pointer;
//    font-size: 16px;
//    color: #fff;
//    -webkit-user-select: none;
//    -moz-user-select: none;
//    -ms-user-select: none;
//    user-select: none;
//}

//.col-xs-12.col-sm-6.form-group.radio-group-btns,
//.col-xs-12.col-sm-6.form-group.checkbox-group
//{
//    width: 25%;
//}
//.input-section.row.blocChoix {
//    margin-top: 15px;
//    margin-bottom: 15px;
//}
//.blocChoix .col-xs-12.col-sm-6.form-group.blocCheckbox .content-radios-btns label {
//    color: #fff;
//}
/*************************************/

section.section-01-index {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        transition: .5s;
        padding: 0;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            transition: .5s;
        }
        div.left-side-sect-01-index,
        div.right-side-sect-01-index {
            width: 50%;
            min-height: 40vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
        }
        div.left-side-sect-01-index {
            background: url(../images/bg_accueil_zone01.jpg) no-repeat center center;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            div.header-left-side {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 35px;
                transition: .5s;
            }
            div.content-paragraphs {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-top: 25px;
                transition: .5s;
                p {
                    width: 62%;
                    font-weight: 300;
                    color: $color-white;
                    font-size: 1.4vw;
                    margin-bottom: 25px;
                    line-height: 1.4;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 1.7vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 85%;
                            font-size: 16px;
                            transition: .5s;
                        }
                    }
                    span {
                        font-size: 1.8vw;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 2.1vw;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 22px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
            div.points-bottom {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 25px 5px;
                transition: .5s;
                div {
                    transition: .5s;
                    &:nth-child(2) {
                        margin: 0 8px;
                        transition: .5s;
                    }
                }
            }
        }
        div.right-side-sect-01-index {
            display: flex;
            align-items: center;
            background: $color-black;
            justify-content: center;
            flex-direction: column;
            padding: 65px 25px;
            padding-top: 15px;
            div.header-right-side-sect-01-index {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                transition: .5s;
                h3 {
                    width: 100%;
                    font-weight: 900;
                    font-size: 2.2vw;
                    text-transform: none;
                    transition: .5s;
                    @media screen and (max-width: $size-max-1) {
                        font-size: 2.0vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 2.8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-4) {
                                    margin-bottom: 10px;
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
            div.content-bursts {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: .5s;
                div.burst {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color-white;
                    border-radius: 15px;
                    margin-bottom: 15px;
                    padding: 15px 25px;
                    //padding-right: 5px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-4) {
                        padding: 12px;
                        transition: .5s;
                    }
                    a {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            flex-direction: column;
                            transition: .5s;
                        }
                        div.left-side-burst {
                            width: 40%;
                            padding-right: 10px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                width: 100%;
                                transition: .5s;
                            }
                            h3 {
                                font-weight: 900;
                                font-size: 3.5vw;
                                margin-bottom: 15px;
                                text-transform: none;
                                color: $color-3;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 4.8vw;
                                    transition: .5s;
                                }
                            }
                            p {
                                color: $color-black;
                                font-size: 1.2vw;
                                line-height: 1.4;
                                font-weight: 400;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 1.4vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 1.8vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 15px;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                        div.rigth-side-burst {
                            width: 60%;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                width: 100%;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-02-index {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        padding: 25px;
        align-items: center;
        justify-content: center;
        padding-right: 15px;
        padding-left: 5px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            padding-left: 18px;
            padding-right: 15px;
            flex-direction: column;
            transition: .5s;
        }
        div.left-side-sect-02-index {
            width: 30%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 94%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-top: 25px;
                transition: .5s;
            }
            div.box-burst-02 {
                width: 100%;
                height: 10vw;
                min-height: 150px;
                background: url(../images/burst_accueil.png) no-repeat center center;
                background-size: 100% 100%;
                transition: .5s;
                margin: 15px;
                @media screen and (max-width: $size-max-3) {
                    min-height: 125px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        margin: 0 0;
                        transition: .5s;
                    }
                }
                &:nth-child(2) {
                    margin: 25px 15px;
                    @media screen and (max-width: $size-max-2) {
                        margin: 0 15px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            margin: 15px 0;
                            transition: .5s;
                        }
                    }
                }
                &:hover {
                    background-size: 0 0;
                    transition: .5s;
                    a {
                        transition: .5s;
                        background: $color-white;
                        border: 3px solid $color-black;
                        border-radius: 15px;
                        div.left-in-burst {
                            transition: .5s;
                            h3 {
                                color: $color-black!important;
                                transition: .5s;
                            }
                        }
                    }
                    h3.big-title,
                    h3.normal-title {
                        text-shadow: none;
                        //text-shadow: 0 0 0 $color-black;
                        color: $color-black;
                        transition: .5s;
                    }
                }
                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 3px solid transparent;
                    border-radius: 15px;
                    transition: .5s;
                    padding: 15px;
                    div.left-in-burst {
                        width: 85%;
                        padding-left: .5vw;
                        transition: .5s;
                        h3.big-title {
                            font-size: 1.9vw;
                            text-shadow: 1px 2px 0 $color-black;
                            margin-bottom: 10px;
                            font-weight: 600;
                            @media screen and (max-width: $size-max-1) {
                                font-size: 2.0vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 2.1vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 2.8vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 26px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-4) {
                                                font-size: 20px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        h3.normal-title {
                            color: $color-1;
                            font-size: 1.3vw;
                            text-shadow: 1px 2px 0 $color-black;
                            margin-bottom: 5px;
                            font-weight: 500;
                            @media screen and (max-width: $size-max-1) {
                                font-size: 1.8vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 1.5vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 2.2vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 22px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-4) {
                                                font-size: 16px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div.right-in-burst {
                        width: 15%;
                        padding-bottom: 8px;
                        transition: .5s;
                        img {
                            height: 8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                height: 12vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    height: 95px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.middle-sect-02-index {
            width: 45%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 1.5vw;
            flex-wrap: wrap;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
                padding: 0;
            }
            div.content-img-middle {
                width: 45%;
                min-width: 190px;
                transition: .5s;
                margin: 8px;
                @media screen and (max-width: $size-max-m-1) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media screen and (max-width: $size-max-m-4) {
                        width: 42%;
                        min-width: auto;
                        transition: .5s;
                        margin: 5px;
                    }
                }
            }
        }

        div.right-sect-02-index {
            width: 25%;
            max-height: 40vw;
            overflow: hidden;
            transition: .5s;
            @media screen and (max-width: $size-max-1) {
                max-height: 35vw;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    max-height: 33vw;
                    transition: .5s;
                }
            }
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                display: flex;
                transition: .5s;
                justify-content: center;
                align-items: flex-start;
                min-height: 650px;
            }
            /*
            .fb-page.fb_iframe_widget.fb_iframe_widget_fluid {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                transition: .5s;
                span {
                    width: 100%!important;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    transition: .5s;
                    iframe {
                        width: 100%!important;
                        display: flex;
                        justify-content: center;
                        transition: .5s;
                        html, #facebook, #document {
                            width: 100%!important;
                            display: flex;
                            justify-content: center;
                            transition: .5s;
                            body {
                                width: 100%!important;
                                display: flex;
                                justify-content: center;
                                transition: .5s;
                                div._li {
                                    width: 100%!important;
                                    display: flex;
                                    justify-content: center;
                                    transition: .5s;
                                    div {
                                        width: 100%!important;
                                        display: flex;
                                        justify-content: center;
                                        transition: .5s;
                                        div#u_0_0 {
                                            width: 100%!important;
                                            display: flex;
                                            justify-content: center;
                                            transition: .5s;
                                            div._3hft {
                                                width: 100%!important;
                                                display: flex;
                                                justify-content: center;
                                                transition: .5s;
                                                div._2p3a {
                                                    width: 100vw!important;
                                                    display: flex;
                                                    justify-content: center;
                                                    flex-direction: column;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            */
        }
    }
}

section.section-01-vanite {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url(../images/vanites_01_bg.jpg) no-repeat center center;
    background-size: cover;
    transition: .5s;
    @media screen and (max-width: $size-max-m-2) {
        background-size: 0 0;
        transition: .5s;
    }
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: .5s;
        padding: 0 0;
        div.header-01-vanite {
            width: 100%;
            height: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-grey-dark;
            padding: 15px 15px;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                min-height: 60px;
                transition: .5s;
            }
            h3 {
                text-shadow: 3px 2px 3px $color-black;
                text-transform: none;
                text-align: center;
                font-weight: 400;
                font-size: 1.5vw;
                transition: .5s;
                .big-letters {
                    text-transform: uppercase;
                    font-size: 1.8vw;
                    color: $color-1;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 2.2vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 18px;
                            transition: .5s;
                        }
                    }
                }
                @media screen and (max-width: $size-max-3) {
                    font-size: 1.8vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        font-size: 15px;
                        transition: .5s;
                    }
                }
            }
        }
        div.body-sect-01-vanite {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .5s;
            @media screen and (max-width: $size-max-m-2) {
                flex-direction: column-reverse;
                transition: .5s;
            }
            div.left-side-sect-01-vanite,
            div.right-side-sect-01-vanite {
                width: 50%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
            div.left-side-sect-01-vanite {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 65px 15px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    padding: 25px 15px;
                    transition: .5s;
                }
                div.content-paragraphs {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    p {
                        width: 75%;
                        font-size: 1.2vw;
                        line-height: 1.7vw;
                        font-weight: 400;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 1.5vw;
                            line-height: 2.2vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-2) {
                                width: 85%;
                                font-size: 15px;
                                line-height: 22px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-2) {
                                    width: 95%;
                                    font-size: 16px;
                                    line-height: 25px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-4) {
                                        font-size: 15px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
                div.content-points {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 35px 15px;
                    transition: .5s;
                    img {
                        transition: .5s;
                        &:nth-child(2) {
                            margin: 0 10px;
                        }
                    }
                }
            }

            div.right-side-sect-01-vanite {
                transition: .5s;
                div.content-right-img-vanite {
                    width: 100%;
                    transition: .5s;
                    display: none;
                    @media screen and (max-width: $size-max-m-2) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                    }
                }
                img {
                    width: 100%;
                    transition: .5s;
                }
            }
        }
    }
}

section.section-02-vanite {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-bottom: 25px solid $color-1;
        transition: .5s;
        padding: 0;
        @media screen and (max-width: $size-max-m-2) {
            flex-direction: column;
            transition: .5s;
        }
        div.left-side-02-vanite {
            width: 65%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 50%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
            h3.title-header-02-vanite {
                width: 100%;
                height: 75px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $color-grey-dark;
                padding: 35px 15px;
                font-weight: 400;
                font-size: 1.8vw;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    font-size: 25px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        font-size: 20px;
                        transition: .5s;
                    }
                }
            }
            div.content-materiaux {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: $color-grey-dark;
                flex-wrap: wrap;
                transition: .5s;
                div.box-mat {
                    width: 30%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    transition: .5s;
                    margin: 10px;
                    @media screen and (max-width: $size-max-m-4) {
                        width: 40%;
                        transition: .5s;
                    }
                    div.content-img-materiel {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        // overflow: hidden;
                        align-items: center;
                        justify-content: center;
                        border-radius: 15px;
                        transition: .5s;
                        img {
                            border-radius: 15px;
                            transition: .5s;

                            -webkit-box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.82);
                            -moz-box-shadow:    0px 6px 16px 0px rgba(0, 0, 0, 0.82);
                            box-shadow:         0px 6px 16px 0px rgba(0, 0, 0, 0.82);
                        }
                    }
                    h3 {
                        margin: 8px 0;
                        text-transform: none;
                        font-weight: 400;
                        font-size: 1.2vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 1.4vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 1.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 17px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
            div.content-paragraphs {
                width: 100%;
                min-height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 25px 15px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    padding-bottom: 42px;
                    padding-top: 42px;
                    transition: .5s;
                }
                p {
                    width: 95%;
                    font-weight: 400;
                    //color: $color-white;
                    font-size: 1.4vw;
                    margin-bottom: 25px;
                    line-height: 1.4;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 1.7vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            width: 85%;
                            font-size: 15px;
                            transition: .5s;
                        }
                    }
                    span {
                        font-size: 1.8vw;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 2.1vw;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 22px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        div.right-side-02-vanite {
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            padding:  15px 3vw;
            padding-top: 2vw;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                padding-top: 9vw;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    padding-top: 7vw;
                    transition: .5s;
                }
            }
            @media screen and (max-width: $size-max-m-1) {
                width: 50%;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding-top: 25px;
                margin-top: -8vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    width: 100%;
                    transition: .5s;
                }
            }
            div.box-burst-02 {
                width: 100%;
                height: 10vw;
                min-height: 150px;
                background: url(../images/burst_accueil.png) no-repeat center center;
                background-size: 100% 100%;
                transition: .5s;
                margin: 15px;
                @media screen and (max-width: $size-max-3) {
                    min-height: 125px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        margin: 0 0;
                        transition: .5s;
                    }
                }
                &:nth-child(2) {
                    margin: 25px 15px;
                    @media screen and (max-width: $size-max-2) {
                        margin: 0 15px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            margin: 15px 0;
                            transition: .5s;
                        }
                    }
                }
                &:hover {
                    background-size: 0 0;
                    transition: .5s;
                    a {
                        transition: .5s;
                        background: $color-white;
                        border: 3px solid $color-black;
                        border-radius: 15px;
                        div.left-in-burst {
                            transition: .5s;
                            h3 {
                                color: $color-black!important;
                                transition: .5s;
                            }
                        }
                    }
                    h3.big-title,
                    h3.normal-title {
                        text-shadow: none;
                        text-shadow: 0 0 0 $color-black!important;
                        color: $color-black;
                        transition: .5s;
                    }
                }
                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 3px solid transparent;
                    border-radius: 15px;
                    transition: .5s;
                    padding: 15px;
                    div.left-in-burst {
                        width: 85%;
                        padding-left: .5vw;
                        transition: .5s;
                        h3.big-title {
                            font-size: 1.9vw;
                            text-shadow: 1px 2px 0 $color-black;
                            margin-bottom: 10px;
                            font-weight: 600;
                            @media screen and (max-width: $size-max-1) {
                                font-size: 2.0vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 2.1vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 2.8vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 26px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-4) {
                                                font-size: 20px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        h3.normal-title {
                            color: $color-1;
                            font-size: 1.3vw;
                            text-shadow: 1px 2px 0 $color-black;
                            margin-bottom: 5px;
                            font-weight: 500;
                            @media screen and (max-width: $size-max-1) {
                                font-size: 1.4vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 1.3vw;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-1) {
                                        font-size: 1.8vw;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-2) {
                                            font-size: 22px;
                                            transition: .5s;
                                            @media screen and (max-width: $size-max-m-4) {
                                                font-size: 16px;
                                                transition: .5s;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    div.right-in-burst {
                        width: 15%;
                        padding-bottom: 8px;
                        transition: .5s;
                        img {
                            height: 8vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                height: 12vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    height: 95px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.section-01-promotion {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: .5s;
        padding: 0;
        div.header-01-vanite {
            width: 100%;
            min-height: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-grey-dark;
            flex-direction: column;
            text-transform: none;
            padding: 15px 00;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                min-height: 60px;
                transition: .5s;
            }
            h3 {
                text-shadow: 3px 2px 3px $color-black;
                text-transform: none;
                text-align: center;
                font-weight: 400;
                font-size: 1.5vw;
                transition: .5s;
                .big-letters {
                    text-transform: uppercase;
                    font-size: 1.8vw;
                    color: $color-1;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 2.2vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 18px;
                            transition: .5s;
                        }
                    }
                }
                @media screen and (max-width: $size-max-3) {
                    font-size: 1.8vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        font-size: 15px;
                        transition: .5s;
                    }
                }
            }
        }
        div.header-02-vanite {
            width: 100%;
            min-height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-1;
            flex-direction: column;
            text-transform: none;
            padding: 15px 00;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                min-height: 60px;
                transition: .5s;
            }
            h3.yellow-border {
                color: $color-grey-dark;
                // text-shadow: 1px 0px 1px $color-black;
                text-transform: none;
                text-align: center;
                font-weight: 500;
                font-size: 1.2vw;
                transition: .5s;
                .big-letters {
                    text-transform: uppercase;
                    font-size: 1.8vw;
                    color: $color-1;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 2.2vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 18px;
                            transition: .5s;
                        }
                    }
                }
                @media screen and (max-width: $size-max-3) {
                    font-size: 1.8vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        font-size: 15px;
                        transition: .5s;
                    }
                }
            }
        }
        div.content-banner {
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url(../images/promo_01_bg.jpg) no-repeat center center;
            background-size: cover;
            padding: 45px 15px;
            transition: .5s;
            div.content-img {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .5s;
                img {
                    height: 12vw;
                    transition: .5s;
                }
            }
        }

        div.body-sect-01-promotion {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            background: $color-grey-dark;
            padding: 65px 15px;
            flex-wrap: wrap;
            transition: .5s;
            div.box-4.unique-promotion {
                width: 22%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $color-black;
                background: $color-white;
                flex-direction: column;
                transition: .5s;
                margin: 10px;
                @media screen and (max-width: $size-max-4) {
                    width: 30%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 45%;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-4) {
                            width: 95%;
                            transition: .5s;
                        }
                    }
                }
                &:hover {
                    transition: .5s;
                    transform: scale(1.2);

                    -webkit-box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 1);
                    -moz-box-shadow:    0px 0px 23px 0px rgba(0, 0, 0, 1);
                    box-shadow:         0px 0px 23px 0px rgba(0, 0, 0, 1);

                    div.header-promotion {
                        background: $color-3;
                        transition: .5s;
                        h3 {
                            color: $color-white;
                            transition: .5s;
                        }
                    }
                }
                div.header-promotion {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: $color-grey;
                    padding: 15px 5px;
                    transition: .5s;
                    h3 {
                        width: 100%;
                        font-size: 1.8vw;
                        font-weight: 500;
                        text-align: center;
                        color: $color-white;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 2.0vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 18px;
                                transition: .5s;
                            }
                        }
                    }
                }
                div.body-promotion {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    transition: .5s;
                    div.content-cover-promotion {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: .5s;
                        a {
                            width: 100%;
                            transition: .5s;
                            img {
                                width: 100%;
                                transition: .5s;
                            }
                        }
                    }
                    h3.sku-code {
                        width: 100%;
                        font-size: 1.2vw;
                        color: $color-grey-dark;
                        padding: 15px 8px;
                        padding-top: 35px;
                        font-weight: 500;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            font-size: 18px;
                            transition: .5s;
                        }
                    }
                    div.conent-description {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 15px 10px;
                        min-height: 250px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            padding: 8px 10px;
                            transition: .5s;
                        }
                        p {
                            color: $color-grey-dark;
                            font-weight: 400;
                            font-size: 1.2vw;
                            line-height: 1.8vw;
                            width: 100%;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 16px;
                                line-height: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 14px;
                                    line-height: 20px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
                div.footer-promopti {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding: 15px 10px;
                    transition: .5s;
                    h3.price {
                        width: 100%;
                        color: $color-3;
                        text-align: right;
                        text-transform: none;
                        font-size: 2.5vw;
                        font-weight: 700;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 3.2vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-4) {
                                font-size: 24px;
                                transition: .5s;
                            }
                        }
                    }
                    h3.note-promotion {
                        width: 100%;
                        margin-top: 12px;
                        text-transform: none;
                        color: $color-grey-dark;
                        font-weight: 400;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}

section.setion-01-coordonees {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-grey-dark;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 75px 15px;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            flex-direction: column;
            padding-top: 15px;
            transition: .5s;
        }
        div.left-side-01-coordonnees,
        div.right-side-01-coordonnees {
            width: 50%;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                transition: .5s;
            }
            div.top-infos-01-coordonnees {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                //padding-left: 4vw;
                min-height: 310px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    padding: 15px;
                }
                h3.city-name {
                    width: 100%;
                    text-align: center;
                    font-size: 32px;
                    font-weight: 500;
                    color: $color-1;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 28px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 24px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    width: 100%;
                                    text-align: center;
                                     transition: .5s;
                                }
                            }
                        }
                    }
                }
                h3.local-address {
                    width: 100%;
                    text-align: center;
                    margin: 25px 0;
                    //padding-left: 2vw;
                    text-transform: none;
                    line-height: 28px;
                    font-weight: 400;
                    font-size: 20px;
                    transition: .5s;
                    margin-top: 5px;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 18px;
                        line-height: 28px;
                        font-weight: 300;
                        transition: .5s;
                    }
                }

                h3.title-heure-ouverture {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 15px;
                    font-size: 22px;
                    font-weight: 500;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 18px;
                        transition: .5s;
                    }

                }
                h3.heure-normal {
                    width: 100%;
                    text-align: center;
                    // padding-left: 2vw;
                    text-transform: none;
                    margin-bottom: 8px;
                    font-weight: 400;
                    font-size: 18px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 16px;
                        margin-bottom: 4px;
                        font-weight: 300;
                        transition: .5s;
                    }
                }
            }

            div.map {
                width: 100%;
                display: flex;
                align-content: center;
                justify-content: center;
                flex-direction: column;
                margin-top: 45px;
                transition: .5s;
                div.header-map {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 15px;
                    padding-right: 2vw;
                    transition: .5s;
                    a {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            align-items: center;
                            justify-content: center;
                            transition: .5s;
                         }
                        div.img-360 {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: .5s;
                            img {
                                min-width: 65px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-2) {
                                    width: 12vw;
                                    transition: .5s;
                                }
                            }
                        }
                        h3 {
                            color: $color-1;
                            text-align: right;
                            transition: .5s;
                            @media screen and (max-width: $size-max-2) {
                                font-size: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-3) {
                                    font-size: 18px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-4) {
                                        font-size: 16px;
                                        transition: .5s;
                                        @media screen and (max-width: $size-max-m-1) {
                                            text-align: center;
                                            transition: .5s;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                div.content-map {
                    transition: .5s;
                    padding: 3.5vw;
                    padding-top: 0;
                    @media screen and (max-width: $size-max-m-1) {
                        padding: 5px;
                        transition: .5s;
                    }
                    div.module-google-map {
                        -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 1);
                        -moz-box-shadow:    0px 0px 25px 0px rgba(0, 0, 0, 1);
                        box-shadow:         0px 0px 25px 0px rgba(0, 0, 0, 1);
                    }
                }
            }
        }
        div.right-side-01-coordonnees {
            @media screen and (max-width: $size-max-m-1) {
                margin-top: 65px;
                transition: .5s;
            }
        }
    }
}

section.section-menu-catalogue {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-grey-dark;
    min-height: 85px;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        padding: 0 2vw;

        .nav {
            width: 100%;
            min-height: 60px;
            display: inline-block;
            // padding: 15px 0;
            padding-top: 20px;
            @media screen and (max-width: $size-max-m-3) {
                display: flex;
                flex-direction: column;
                transition: .5;
            }
            li.search-bar {
                float: right;
            }
        }

        .nav li {
            float: left;
            list-style-type: none;
            //border: 2px solid $color-white;
            position: relative;
            min-width: 200px;
            // transition: .5s;
        }

        .nav li a {
            height: 60px;
            display: flex;
            font-size: 15px;
            color: $color-white;
            align-items: center;
            line-height: 35px;
            padding: 0 26px;
            text-decoration: none;
            //border-left: 1px solid #2e2e2e;
            //font-family: Montserrat, sans-serif;
            text-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            // transition: .5s;
        }
        .nav li a:hover {
            //background-color: #2e2e2e;
            color: $color-grey-dark;
            transition: .5s;
        }

        #settings a {
            padding: 18px;
            //height: 24px;
            font-size: 10px;
            line-height: 24px;
            //transition: .5s;
        }

        #search {
            width: 30vw;
            margin: 4px;
            @media screen and (max-width: $size-max-m-1) {
                width: 35vw;
                min-width: 250px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    width: 100%;
                    margin-left: 0;
                    transition: .5s;
                }
            }

            /*the container must be positioned relative:*/
            .autocomplete {
                width: 100%;
                position: relative;
                display: inline-block;
            }

            .autocomplete-items {
                position: absolute;
                border: 1px solid #d4d4d4;
                border-bottom: none;
                border-top: none;
                z-index: 99;
                /*position the autocomplete items to be the same width as the container:*/
                top: 100%;
                left: 0;
                right: 0;
            }

            .autocomplete-items div {
                padding: 10px;
                cursor: pointer;
                background-color: $color-white;
                border-bottom: 1px solid #d4d4d4;
                text-transform: none;
                line-height: 25px;
                font-weight: 300;
            }

            /*when hovering an item:*/
            .autocomplete-items div:hover {
                background-color: #e9e9e9;
            }

            /*when navigating through the items using the arrow keys:*/
            .autocomplete-active {
                background-color: DodgerBlue !important;
                color: #ffffff;
            }

        }
        #search_rst{
            width: 85%;
            padding: 15px 0 15px 20px;
            font-size: 16px;
            font-family: Montserrat, sans-serif;
            border: 0 none;
            height: 52px;
            margin-right: 0;
            color: white;
            outline: none;
            background: #1f7f5c;
            float: left;
            box-sizing: border-box;
            transition: all 0.15s;
        }
        ::-webkit-input-placeholder { /* WebKit browsers */
            color: white;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color: white;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
            color: white;
        }
        :-ms-input-placeholder { /* Internet Explorer 10+ */
            color: white;
        }
        #search_rst:focus {
            background: rgb(64, 151, 119);
        }
        #search_button {
            border: 0 none;
            background: #1f7f5c url(../images/search.png) center no-repeat;
            width: 15%;
            float: left;
            padding: 0;
            text-align: center;
            height: 52px;
            cursor: pointer;
        }
        #options a {
            //border-left: 0 none;
            // transition: .5s;
            &.option-link {
                border: 2px solid $color-white;
            }
        }
        #options > a {
            height: 60px;
            display: flex;
            align-items: center;
            background-image: url(../images/triangle.png);
            background-position: 85% center;
            background-repeat: no-repeat;
            padding-right: 42px;
        }
        .subnav {
            visibility: hidden;
            position: absolute;
            top: 100%;
            right: 0;
            width: 200px;
            height: auto;
            opacity: 0;
            transition: all 0.1s;
            background: #232323;
            padding-left: 0;
        }
        .subnav li {
            float: none;
        }
        .subnav li a {
            border: 0;
            border-bottom: 1px solid #2e2e2e;
        }
        #options:hover .subnav {
            visibility: visible;
            top: 100%;
            opacity: 1;
        }

        /******** Dorpdow *******/
        .dropbtn {
            color: $color-white;
            padding: 4px 15px;
            font-size: 16px;
            border: none;
            cursor: pointer;
            background: transparent;
            border: 2px solid $color-white;
            text-transform: uppercase;
            min-width: 300px;

            height: 60px;
            display: flex;
            align-items: center;
            background-image: url(../images/triangle.png);
            background-position: 85% center;
            background-repeat: no-repeat;
            padding-right: 42px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                min-width: 250px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    min-width: 300px;
                    transition: .5s;
                }
            }
        }

          .dropdown {
            position: relative;
            display: inline-block;
            transition: .5s;
        }

        .dropdown-content {
            display: none;
            min-width: 300px;
            position: absolute;
            background-color: $color-grey-dark;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            transition: .5s;
            z-index: 999;
            @media screen and (max-width: $size-max-m-1) {
                min-width: 250px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-3) {
                    min-width: 300px;
                    transition: .5s;
                }
            }
        }

        .dropdown-content a {
            height: 44px!important;
            display: flex;
            font-size: 12px;
            align-items: center;
            line-height: 35px;
            padding: 12px 16px;
            color: $color-white;
            text-transform: uppercase;
            border: 2px solid $color-white;
            border-top: 1.0px solid $color-white;
            transition: .5s;
            display: block;

        }

        .dropdown-content a:hover {
            background-color: #f1f1f1;
            color: $color-grey-dark;
            transition: .5s;
        }

        .dropdown:hover .dropdown-content {
            display: block;
        }

          .dropdown:hover .dropbtn {
            background-color: $color-grey-dark;
        }
    }
}

section.section-content-products {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/produits_bg_paralax.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 75px 15px;
        flex-wrap: wrap;
        transition: .5s;
        div.box-prod {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 25px;
            transition: .5s;
            padding: 1.5vw;
            @media screen and (max-width: $size-max-3) {
                width: 30%;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    width: 33%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        width: 50%;
                        transition: .5s;
                    }
                }
            }
            div.content-cover-prod {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $color-white;
                border-radius: 2px;
                overflow: hidden;
                transition: .5s;
                a {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 100%;
                    }
                }
            }
            h3.title-name {
                width: 100%;
                margin-top: 15px;
                transition: .5s;
                a.title-link {
                    font-weight: 400;
                    font-size: 1.2vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        font-size: 1.5vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 2.0vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                font-size: 2.2vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-3) {
                                    font-size: 16px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        font-size: 14px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


section.section-catalogue {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/produits_bg_paralax.jpg) no-repeat center center;
    border-bottom: 25px solid $color-1;
    background-attachment: fixed;
    background-size: cover;
    transition: .5s;
    div.container-fluid.row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        transition: .5s;
        padding: 0;

        div.content-titles {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 65px;
            transition: .5s;
            h3.title-categorie {
                width: 100%;
                color: $color-1;
                font-weight: 500;
                text-align: center;
                font-size: 1.8vw;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
                span {
                    color: $color-white;
                }
            }
            h3.sous-title-produt {
                width: 100%;
                font-weight: 400;
                margin-top: 15px;
                text-transform: none;
                color: $color-white;
                text-align: center;
                font-size: 1.0vw;
                transition: .5s;
                @media screen and (max-width: $size-max-4) {
                    font-size: 16px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 14px;
                        transition: .5s;
                    }
                }
                a {
                    color: $color-white;
                }
            }
        }

        div.body-sect-catalogue {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 65px 15px;
            transition: .5s;
            div.content-produits {
                width: 95%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    width: 100%;
                    transition: .5s;
                }
                div.content-produit {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    transition: .5s;
                    margin: 2vw;
                    @media screen and (max-width: $size-max-2) {
                        width: 30%;
                        margin: 1vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            width: 45%;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-5) {
                                width: 95%;
                                transition: .5s;
                            }
                        }
                    }
                    div.content-img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid $color-1;
                        margin-bottom: 10px;
                        border-radius: 2px;
                        overflow: hidden;
                        transition: .5s;
                        padding: 0;

                        -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.87);
                        -moz-box-shadow:    1px 1px 15px 0px rgba(0, 0, 0, 0.87);
                        box-shadow:         1px 1px 15px 0px rgba(0, 0, 0, 0.87);
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            position: relative;
                            transition: .5s;
                            img {
                                transition: .5s;
                            }
                            &::after {
                                content: "";
                                top: 0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                position: absolute;
                                background: $color-1;
                                z-index: 99;
                                opacity: 0;
                            }
                        }
                        &:hover {
                            transition: .5s;
                            a {
                                transition: .5s;
                                img {
                                    transform: scale(1.4);
                                    transition: .5s;
                                }
                                &::after {
                                    transition: .5s;
                                    opacity: .6;
                                }
                            }
                        }
                    }
                    h3.title-product {
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        font-weight: 400;
                        padding-top: 5px;
                        color: $color-1;
                        transition: .5s;
                        font-size: 18px;
                        height: 75px;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 1.4vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 1.7vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 16px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-4) {
                                        font-size: 13px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            div.mfp-wrap.mfp-gallery {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                transition: .5s;
            }
        }
    }
}

.white-popup {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    //background: $color-white;
    flex-direction: column;
    position: relative;
    margin: 20px auto;
    min-width: 650px;
    @media screen and (max-width: $size-max-m-1) {
        min-width: 85%;
        transition: .5s;
    }
}

div.mfp-content {
    transition: .5s;
    .mfp-close {
        font-size: 1.5vw;
        color: $color-white;
        transition: .5s;
    }
    div.produ-fsb-popup {
        width: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: .5s;
        div.box-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $color-white;
            flex-direction: column;
            min-width: 650px;
            transition: .5s;
            @media screen and (max-width: $size-max-m-1) {
                min-width: 300px;
                transition: .5s;
            }
            div.top-section {
                width: 100%;
                //padding: 8px;
                display: flex;
                padding-top: 6px;
                align-items: flex-start;
                justify-content: center;
                min-height: 352px;
                overflow: hidden;
                transition: .5s;
                height: 28.5vw;
                @media screen and (max-width: $size-max-2) {
                    height: 29.5vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        height: 30.5vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-4) {
                            height: 33.5vw;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                height: 45.5vw;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    height: auto;
                                    min-height: auto;
                                    align-items: center;
                                    justify-content: flex-start;
                                    flex-direction: column;
                                    transition: .5s;
                                    padding: 8px;
                                }
                            }
                        }
                    }
                }
                div.cotent-cover {
                    width: 99%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-2) {
                        width: 100%;
                        transition: .5s;
                    }
                }
            }

            div.cotent-thumbs {
                width: 100%;
                padding: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px solid $color-grey-light;
                flex-wrap: wrap;
                transition: .5s;
                padding-top: 0;
                @media screen and (max-width: $size-max-m-2) {
                    padding: 0;
                    height: auto;
                    padding-top: 8px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    transition: .5s;
                }
                div.content-thumb {
                    width: 13.8%;
                    display: flex;
                    margin-bottom: 8px;
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                    margin: 4px;
                    margin-left: 0;
                    @media screen and (max-width: $size-max-m-2) {
                        margin-right: 2.2px;
                        transition: .5s;
                        width: 15vw;
                        @media screen and (max-width: $size-max-m-4) {
                            transition: .5s;
                            width: 22vw;
                        }
                    }
                    &:nth-last-child(1) {
                        margin-right: 0px;
                    }
                }
                img {
                    cursor: pointer;
                    transition: .5s;
                }
            }

            div.bottom-section {
                width: 100%;
                display: flex;
                padding: 2vw 1vw;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                padding-bottom: 0;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 2vw 15px;
                    transition: .5s;
                }
                h3.title-prod {
                    width: 100%;
                    font-weight: 500;
                    font-size: 1.8vw;
                    font-family: $font-family-1;
                    color: $color-grey-dark;
                    margin-bottom: 8px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 18px;
                        transition: .5s;
                    }
                }
                h3.code-sku {
                    width: 100%;
                    font-weight: 500;
                    font-family: $font-family-1;
                    color: $color-grey-dark;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 17px;
                        transition: .5s;
                    }
                }
                div.content-description {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    padding-top: 15px;
                    transition: .5s;
                    p {
                        width: 100%;
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 25px;
                        color: $color-grey-dark;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 17px;
                            line-height: 22px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 16px;
                                line-height: 22px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 15px;
                                    line-height: 22px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
            div.footer-descrition {
                width: 100%;
                display: flex;
                padding: 15px 1vw;
                align-items: center;
                justify-content: center;
                padding-bottom: 2vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 2vw 15px;
                    transition: .5s;
                }
                div.left-side-footer,
                div.price-content {
                    width: 50%;
                    transition: .5s;
                }
                div.left-side-footer {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-direction: column;
                    h3 {
                        color: $color-grey-dark;
                        margin-bottom: 5px;
                        font-weight: 400;
                        font-size: 1.2vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 15px;
                            transition: .5s;
                        }
                    }
                    h4 {
                        color: $color-grey-dark;
                        font-weight: 500;
                        font-size: 14px;
                        transition: .5s;
                    }
                }
                div.price-content {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    h3 {
                        font-family: $font-family-1;
                        color: $color-3;
                        font-weight: 600;
                        font-size: 2.2vw;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            font-size: 20px;
                            transition: .5s;
                        }
                    }
                }
            }
        }
    }
}

div.empty-products {
    width: 100%;
    height: 8vw;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: .5s;
    h3 {
        width: 100%;
        font-size: 20px;
        color: $color-grey;
        text-transform: none;
        text-align: center;
        font-weight: 400;
        transition: .5s;
    }
}

.image-link {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

/* aligns caption to center */
.mfp-title {
    text-align: center;
    padding: 6px 0;
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content:"";
    display:block;
    height:$size-nav-height; /* fixed header height*/
    margin:-$size-nav-height 0 0; /* negative fixed header height */
}

#firstHeading {
    color: $color-grey-dark;
}

div.title-menu {
    width: 100%;
    transition: .5s;
}

div.wood-bar {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../images/bande_bois-2.png) no-repeat center center;
    background-size: cover;
    transition: .5s;
}

.image-link {
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

/* aligns caption to center */
.mfp-title {
    text-align: center;
    padding: 6px 0;
}


.mega-buld {
    font-weight: 800;
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* POP-UP
/*****************************************************/

div#modal-1 {
    position: absolute;

    .popup-box { 

        h3 {
            width: 100%;
            color: #fdc056;
            font-weight: 500;
            font-size: 2vw;
            text-transform: initial;
        }

        p {
            font-weight: 300;
            color: #000;
            font-size: 1.4vw;
            margin-bottom: 25px;
            line-height: 1.4;
        }
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
@import 'sections/section-header-page';

//@import 'sections/section-text-image';

@import 'sections/section-bursts';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
@import 'modules/module-header';
@import 'modules/module-soumission';


/*******************************************************************************
* EMPLOIS
*******************************************************************************/

#section-emploi {
    background: #282727;
    padding: 75px 0;
    .text {
        padding: 50px 10%;
        p {
            font-weight: normal;
            font-size: $font-size-20;
            color: #fff;
            margin: 0;
            line-height: 1.5;
        }
        ul {
            padding: 25px 0 35px 75px;
            li {
                font-weight: normal;
                font-size: $font-size-20;
                color: #fff;
                margin: 0;
                line-height: 1.5;
                text-transform: initial;
            }
        }
    }
    .titre {
        background: #fdc056;
        padding: 15px 2%;
        text-align: center;
        h3 {
            font-weight: bold;
            font-size: $font-size-26;
            color: #282727;
            text-transform: initial;
        }
        h3.last {
            text-transform: uppercase;
        }
    }
    .emploi {
        padding: 75px 10% 0 10%;
        .bloc {
            padding-bottom: 75px;
            h3 {
                font-weight: bold;
                font-size: $font-size-24;
                text-transform: initial;
                color: #fdc056;
                padding-bottom: 20px;
            }
            p {
                font-weight: normal;
                font-size: $font-size-20;
                color: #fff;
                margin: 0;
                line-height: 1.5;
            }
            ul {
                padding: 25px 0 35px 75px;
                li {
                    font-weight: normal;
                    font-size: $font-size-20;
                    color: #fff;
                    margin: 0;
                    line-height: 1.5;
                    text-transform: initial;
                }
            }
            &:last-child {
                padding-bottom: 25px;
            }
        }
        .bouton {
            a.btnPostulez {
                background: #fdc056;
                font-weight: bold;
                font-size: $font-size-24;
                color: #fff;
                padding: 10px 100px;
                &:hover {
                    color: #000;
                }
            }
        }
    }
}

#section-formulaire {
    background: #000;
    .section-form {
        background: none;
        label.control-label {
            color: #fff;
            font-size: $font-size-22;
            width: 40%;
            font-weight: normal;
        }
        label.image {
            background: #535353;
            text-align: center;
            font-weight: 500;
            font-size: $font-size-22;
            padding: 19.5px;
            margin: 0;
            width: 290px;
            color: #fff;
        }
        textarea#message {
            margin-left: -8.3%;
        }
        #right {
            display: flex;
            align-items: center;
            margin-left: 17.5%;
            padding-top: 25px;
            .form-group {
                margin-bottom: 0;
                button#submit {
                    color: #fff;
                    font-size: $font-size-22;
                    padding: 12px;
                    &:hover {
                        color: #000;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

.section-menu-filter {
    width: 100%;
    min-height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #353535;
    flex-direction: column;
    text-transform: none;
    padding: 15px 00;
    transition: .5s;
    border-bottom: 1px solid #fdc056;
    ul.menu-filter {
        list-style: none;
        li.filter-item {
            button {
                background: none;
                border: none;
                text-shadow: 3px 2px 3px #000;
                text-transform: none;
                text-align: center;
                font-weight: 400;
                font-size: $font-size-28;
                color: white;
                transition: .5s;
                &:hover {
                    color: #fdc056;
                    transition: .5s;
                }
            }
            span {
                font-weight: normal;
                font-size: $font-size-28;
                color: #fdc056;
                padding: 0 25px;
            }
            &:last-child {
                span {
                    display: none;
                }
            }
        }
    }
    @media screen and (max-width: $size-sm-max){
        ul.menu-filter {
            li.filter-item {
                button, span {
                    font-size: 1.2rem;
                }
            }
        }
        @media screen and (max-width: $size-xs-max){
            ul.menu-filter {
                flex-direction: column;
                li.filter-item {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

section.gallerie-products {
    padding: 75px 10%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    background: #353535;
    .img-responsive {
        width: 100%;
    }
    @media screen and (max-width: 1450px){
        grid-template-columns: repeat(3, 1fr);  
        @media screen and (max-width: $size-sm-max){
            grid-template-columns: repeat(2, 1fr);  
            @media screen and (max-width: $size-xs-max){
                padding: 75px 5%;
                grid-template-columns: repeat(1, 1fr);                
            }
        }
    }
}








/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {

div#modal-1 .popup-box h3 {
    font-size: 3.5vw;
}
div#modal-1 .popup-box p {
    font-size: 2vw;
}
}


@media screen and (max-width: $size-sm-max) {

div#modal-1 .popup-box h3 {
    font-size: 4.5vw;
}
div#modal-1 .popup-box p {
    font-size: 3vw;
}
#section-emploi .text ul, #section-emploi .emploi .bloc ul {
    padding: 25px 0 35px 25px;
}
#section-emploi .text {
    padding: 50px 5%;
}
#section-emploi .emploi {
    padding: 50px 5% 0 5%;
}
#section-formulaire .section-form #right {
    margin-left: 16.5%;
}
}


@media screen and (max-width: $size-xs-max) {

div#modal-1 .popup-box h3 {
    font-size: 6vw;
}
div#modal-1 .popup-box p {
    font-size: 4vw;
}
#section-formulaire .form-group .flex {
    flex-direction: column;
}
#section-formulaire .section-form {
    padding: 50px 0;
}
#section-formulaire .section-form label.control-label {
    width: 100%;
}
#section-formulaire .section-form textarea#message {
    margin-left: 0;
}
#section-formulaire .section-form #right {
    margin-left: 0;
    flex-direction: column;
    align-items: baseline;
}
#section-formulaire .section-form #right .form-group button#submit {
    margin-bottom: 15px;
}
}
