.slideshow-wrap {
    position: relative;
    background-color: $bg-color-body;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {        
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $z-index-slide-caption;
        position: absolute;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;
        padding: 15px 3vw;
        overflow: hidden;        
        height: 100%;
        width: 100%;        
        div.row.images {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            margin-top: 14vw;
            transition: .5s;
            @media screen and (max-width: $size-max-m-4) {
                margin-top: -50vw;
                transition: .5s;
            }
            img {
                width: 50vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    width: 85vw;
                    transition: .5s;
                }
                &:nth-child(1) {
                    opacity: 1;
                }
                &:nth-child(2) {
                    position: absolute;
                    opacity: 1;
                }
            }
            @media screen and (max-width: $size-max-m-4) {
                width: 100%;
                justify-content: center;
                transition: .5s;
            }
        }
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}
        .caption {
            top: $size-nav-height-mobile + 20px;
        }

    }
}

