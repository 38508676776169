
//********************/
// COLORS
//
//$color-1: rgb(253, 192, 86); // needed
//$color-2: rgb(255, 255, 255); // needed
//$color-3: $color-1;
//$color-4: rgba(255, 255, 255, 0.98);
//$color-5: #093188;

//$color-primary: $color-1; // needed
//$color-secondary: $color-2; // needed
//$color-tertiary: $color-1; // needed

//$color-black: #000; // needed
//$color-white: #fff; // needed
//$color-grey: #58585a; // needed
//$color-grey-dark: #353535; // needed body
//$color-grey-darker: #262523; // footer
//$color-grey-light: #b5b5b5;

$color-error: red; // needed error messages
$color-success: green; // needed success messages

$input_height: 45px;
$input_back_color: #fff;
$input_text_color: #000;
$input_text_size: 20px;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: 18px;

//$input_border_color: $color-primary;
//$input_border_radius: 0;
//$input_border_size: 1px;
//$input_border_style: solid;

//$input_margin: 10px 0;
//$input_text_color: $color-white;
$input_label_color: $color-1;
//$label_checkbox_border_color: $label-color;
//$input_checked_color: $color-primary;

$textarea_height: 200px;

$message_errors_text_color: white;
$message_errors_border_color: hsla(0, 100%, 55%, 1);
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: white;
$message_success_border_color: hsla(120, 100%, 50%, 1);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: hsla(120, 100%, 50%, 0.5);


div.section-form {
    padding: 85px 0;
    // background: url(../images/bg_section02.jpg) no-repeat center center;
    // background-attachment: fixed;
    // background-size: cover;
    background: $color-grey-dark;
    transition: 0.5s;

    div.module-contact-form {
        div.footer-form {
            display: flex;
            flex-direction: column;
            align-content: center;
            align-items: center;
            div.form-group {
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
            }
        }
    }

    .input-section {
        input[type=text],
        input[type=email],
        input[type=tel],
        textarea {
            height: $input_height;
            font-size: 16px;
            font-weight: 500;
            border-radius: 1px;
            color: $color-black;
            @media screen and (max-width: 1575px) {
                font-size: 14px;
            }
        }
        label, .title-option {
            color: $input_label_color;
            font-size: 18px;
            font-weight: 500;
            @media screen and (max-width: 1575px) {
                font-size: 15px;
                @media screen and (max-width: 767px) {
                    font-size: 15px;
                }
            }
        }
        .title-option {
            padding-bottom: 12px;
            text-transform: none;
        }
        textarea {
            resize: vertical;
            height: auto;
        }
        .radio-group,
        .checkbox-group {
            .radio-container, .checkbox-container {
                display: flex;
                @extend .items-center;
                position: relative;
                padding-left: 35px;
                margin-bottom: 10px;
                cursor: pointer;
                user-select: none;
                color: $color-white;
                font-size: $font-size-18;
                font-weight: 300;

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }
                .custom-radio, .custom-checkbox {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    background-color: $color-white;
                    border-radius: 50%;
                    transition: 0.2s;
                    transform: translateY(-50%);
                }
                .custom-checkbox {
                    border-radius: 0px;
                }
                .custom-radio:after, .custom-checkbox:after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 5px;
                    left: 5px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: $color-white;
                }

                .custom-checkbox:after {
                    border-radius: 0px;
                    background-color: $color-1;
                    left: 7px;
                    top: 1px;
                    width: 7px;
                    height: 15px;
                    border: solid black;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }
                &:hover {
                    input ~ .custom-radio,
                    input ~ .custom-checkbox
                    {
                        //background-color: darken($color-1, 20%);
                        transition: 0.2s;
                    }
                }
                input:checked {
                    & ~ .custom-radio, & ~ .custom-checkbox {
                        background-color: $color-1;
                    }
                    & ~ .custom-radio:after, & ~ .custom-checkbox:after {
                        display: block;
                    }
                }
            }
        }
        label.btn-fileDoc {
            width: 50%;
            height: 50px;
            min-width: 300px;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid $color-white;
            font-family: $font-family-1;
            background: $color-1;
            color: $color-white;
            font-size: 18px;
            padding: 0 25px;
            transition: .5s;
            &:hover {
                background: $color-1;
                transition: .5s;
            }
        }
        div.btn-submit {
            button {
                width: 50%;
                height: 50px;
                min-width: 300px;
                font-weight: 400;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $color-white;
                font-family: $font-family-1;
                background: $color-1;
                color: $color-white;
                font-size: 18px;
                padding: 0 25px;
                transition: .5s;
                &:hover {
                    background: $color-1;
                    transition: .5s;
                }
            }
        }
    }

    div#fp {
        .preview-file {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            transition: .5s;
            span {
                color: $color-white;
                font-weight: 300;
                font-size: 16px;
            }
        }
    }

    div.help-block.with-errors {
        ul.list-unstyled {
            li {
                color: #a94442;
                position: absolute;
                line-height: 15px;
                margin-top: -4px;
                font-size: 13px;
                transition: .5s;
            }
        }
    }


    // to put outline around radio and checkboxes
    input[type="file"]:focus ~ span,
    input[type="radio"]:focus ~ span,
    input[type="checkbox"]:focus ~ span {
        outline: none;
        box-shadow: 0px 0px 10px $color-1;
    }

}

.pd-0-0 {
    padding: 0;
}

div.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display:none;
    background-color: rgba(0,0,0,0.7);
    background-size: cover;
    z-index: 9999;
    &.active {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border: 16px solid $bg-color-body;
    border-radius: 50%;
    border-top: 16px solid $color-secondary;
    border-bottom: 16px solid $color-primary;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.8), inset 0px 0px 5px rgba(0,0,0,0.8);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
